module.exports = {
    home:'首页',
    audiotovideolang:'录音档转影片',
    speakerlang:'影音人物标记',
    translatelang:'自动影片翻译',
    aianalysislang:'AI分析工具',
    addsubtitleslang:'自动影片上字幕',
    converttextlang:'影音转为逐字稿',
    collaboratelang:'用户管理和权限分配',
    transcriptstosubtitleslang:'汇入TXT或SRT档案',
    transcriptstospeakerslang:'逐字稿人物标记',
}