module.exports = { 
    home: 'Accueil',
    audiotovideolang: 'Convertir un audio en vidéo',
    speakerlang: 'Étiquetage des intervenants pour les vidéos',
    translatelang: 'Traduire une vidéo',
    aianalysislang: 'Outil d\'analyse IA',
    addsubtitleslang: 'Ajouter des sous-titres à une vidéo',
    converttextlang: 'Convertir une vidéo en texte',
    collaboratelang: 'Collaborer en équipe',
    transcriptstospeakerslang: 'Ajouter des étiquettes d\'intervenants aux transcriptions existantes',
    transcriptstosubtitleslang: 'Importer un fichier TXT ou SRT',
}