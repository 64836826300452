module.exports = { 
    monthly_free_minutes: '毎月の無料時間 (サブスクリプション状態でのみ有効)',
    monthly_free_minutes_tooltip: 'この分は、サブスクリプションがキャンセルされるまで継続して累積できます。 <br>この分は、別途購入した分よりも優先して使用されます。 ',
    additional_seat: 'アカウント共有ユーザー',
    additional_seat_tooltip: 'ユーザーを招待してアカウントを共有し、議事録、テキストファイル、ビデオ、機能へのアクセス権を付与します。権限はカスタマイズ可能です。',
    monthly_free_ai_credits: 'AI ポイント<a href="https://www.taption.com/ai-analysis/jp" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">詳細</a>',
    monthly_free_ai_credits_tooltip: '購読後、AI ポイントは毎月自動的に補充され、いつでも追加のポイントを購入できます。 <br>「アドバンス サブスクリプション」は毎月最大 {0} ポイント補充できます<br>「ラージ サブスクリプション」は毎月最大 {1} ポイント補充できます',
    monthly_free_ai_credits_desc: '毎月最大 {0} ポイントを補充します',
    minutes_price: '映画の分購入価格',
    minutes_price_tooltip: '追加購入した分数は退会しても無効になりません。 ',
    max_file_length: 'アップロードされた動画の最大長',
    max_file_size: 'アップロード ファイル サイズの制限',
    max_label_number: '動画ラベル分類上限',
    automated_transcription: 'オーディオおよびビデオ ファイルを自動的にテキストに変換します',
    editing_platform_unlimited: '編集プラットフォームには時間制限がありません',
    language_supported: '40以上の言語をサポート',
    highlight_text: '正確な時間比較エディター',
    translate: '50 以上の言語での翻訳',
    translate_basic_tooltip: '翻訳には分が差し引かれます',
    translate_premium_tooltip: '翻訳には分が差し引かれます',
    translate_corp_tooltip: '翻訳には分が差し引かれます',
    personal_dictionary: 'パーソナル AI 辞書',
    allow_commercial: '商用利用可能',
    mp4_multi_language_export_tooltip: '50以上の言語のバイリンガル字幕をサポートします。編集ページに入った後、右上隅の [翻訳] メニューからこのビデオを翻訳することで、バイリンガル字幕オプションを追加できます。 ',
    mp4_multi_language_export: 'ムービー エクスポートに埋め込まれたバイリンガルの字幕',
    mp4_multi_language_export_basic_tooltip: 'ムービーのエクスポートを購読していない場合、分数が差し引かれます',
    mp4_audio_export: '録音をアップロードし、カスタマイズされた背景で字幕ビデオをエクスポートします',
    mp4_audio_export_tooltip: '音声ファイルを動画としてエクスポートすると、動画の背景をカスタマイズしたり、動画に (バイリンガルの) 字幕を追加したりできます。 ',
    mp4_audio_export_basic_tooltip: 'ビデオの背景のカスタマイズはサブスクライバーのみが利用でき、非サブスクライバーは黒の背景でエクスポートされます. ',
    api_integration: 'カスタム API 統合',
    api_integration_tooltip: '詳細については、help@taption.com までお問い合わせください',
    api_integration_basic_tooltip: '詳細については、help@taption.com までお問い合わせください',
    api_integration_premium_tooltip: '詳細については、help@taption.com までお問い合わせください',
    api_integration_corp_tooltip: '詳細については、help@taption.com までお問い合わせください',
    custom_payments: 'カスタム請求オプション',
    custom_payments_tooltip: '銀行振込や小切手など、柔軟な支払い方法をご利用いただけます。詳細については、help@taption.com までお問い合わせください。',
    advanced_training: 'ユーザー向け高度なトレーニング',
    advanced_training_tooltip: 'チーム全員の導入をサポートいたします。専門スタッフによる丁寧な研修をご用意しております',
    high_definition: '元の品質のビデオ',
    high_definition_tooltip: 'ビデオを元の品質で編集して共有する',
    mp4_export: '字幕をムービーにエクスポート',
    mp4_export_basic_tooltip: 'ムービーのエクスポートを購読していない場合、分数が差し引かれます',
    editable_transcript_sharing: '他のユーザーと共有して編集する',
    dark_mode: 'ダークモード',
    video_editing: '字幕付きのビデオクリップを編集',
    video_cutting: '動画カット機能',
    multi_upload: '動画の一括アップロード',
    invoice_company: '請求書には会社名が表示されます',
    invoice_taxid: '請求書には統一番号が表示されます',
    share_minutes: 'ユーザーは議事録を共有します',
    share_minutes_tooltip: 'このアカウントの議事録とビデオを共有するユーザーを追加',
    permission_users: '参加ユーザーごとに6種類の権限管理を提供<a href="https://www.taption.com/collaborate/jp" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">詳細</a>',
    permission_users_tooltip: '各ユーザーの翻訳、アップロード、削除、エクスポート、編集などの権限を制限します',
    max_char: '字幕あたりの最大文字数',
    max_char_tooltip: '翻訳されたテキストはこの値に従ってセグメント化され、各セグメントはこの単語数を超えません。',
    direct_link: 'さまざまなビデオおよびオーディオ ファイルの URL リンクをサポート (アップロードする必要はありません)',
    direct_link_tooltip: '次をサポートします:<br>• Facebookの動画リンク<br>• Instagramの動画リンク<br>• X（旧Twitter）の動画リンク<br>• TikTokの動画リンク<br>• クラウド ビデオ URL リンク<br>• Dropbox共有リンク<br>• One Drive共有リンク<br>• Google Drive共有リンク<br><br>',
    auto_extent_subtitle: '字幕ごとに再生時間を自動延長する',
    auto_extent_subtitle_tooltip: '以下指定された時間通り、すべての字幕を延長する\'。<br/> 延長した再生時間が次の字幕と被る場合は、重複を避けるため次の字幕の直前まで制限的に延長されます。<br/>そうすることで、字幕のない刹那の瞬間に字幕が点滅するようなブリンキング現象を防ぎ、字幕の転換がスムーズになります。',
    clipped_video: '時間範囲内で文字起こしする',
    clipped_video_tooltip: 'メディア ファイルの選択した時間範囲内で抽出して転写します。<br>Youtube、mp3、mp4 タイプのメディアをサポートします。',
    custom_format: 'カスタマイズされた形式のエクスポート',
    custom_format_tooltip: 'TXTまたはPDF形式でアーカイブをダウンロードすることを選択すると、各期間、テキスト、個人名、コメントの出力形式を個人のニーズに応じて調整できます。',
    editing_font_size: '編集プラットフォームのフォント サイズを調整します',
    low_cost: '手頃な価格',
    title_basic: '映画の長さの料金',
    basic_cost_usd: '$8',
    basic_cost_ntd: '(1170 JPY)',
    unit: 'ドル',
    hour: '時間',
    basic_feature5: '補充<span style="color: #00d1b2;">60分</span>',
    basic_feature1: '動画ファイルをテキストに自動変換',
    basic_feature2: '40以上の言語をサポート',
    basic_feature3: '正確な時間比較エディタ',
    basic_feature4: '50 以上の言語に翻訳',
    basic_feature7: 'パーソナル AI 辞書',
    basic_feature6: '商用利用可能',
    basic_feature8: 'ビデオ エクスポートに埋め込まれたバイリンガルの字幕',
    title_premium: 'プレミアムサブスクリプション',
    premium_cost_usd: '$12',
    premium_cost_ntd: '(1755 JPY)',
    premium_basic_cost_usd: '6 USD',
    month: '月',
    premium_before: '<span class="landing-arrow-left">&#10229;</span>すべての「動画の長さの料金設定」機能',
    premium_feature1: '毎月プレゼント<span style="color: #00d1b2;">120分</span> <br><span style="color:#797676;font-size:14px;font-weight: 100;">(月をまたいで累積可能、サブスクリプションがキャンセルされるまで有効)</span>',
    premium_feature1_tooltip: 'この分は、サブスクリプションがキャンセルされるまで蓄積され続ける可能性があります. <br>この分は、別途購入した分よりも優先して使用されます。 <br>追加購入した分は、退会しても失われません。 ',
    premium_feature2: 'さらに数分間、25% オフ',
    premium_feature3: 'ビデオに埋め込まれた字幕は分数を差し引かず、無制限にエクスポート',
    premium_feature4: '編集のために他のユーザーと共有',
    premium_feature5: '字幕付きビデオへの録画',
    premium_feature6: 'ビデオクリップ (字幕自動調整)',
    title_corp: '大きいサブスクリプション',
    bulk_cost_usd: '$69',
    bulk_cost_ntd: '(10090 JPY)',
    bulk_basic_cost_usd: '4 USD',
    bulk_before: '<span class="landing-arrow-left">&#10229;</span>すべての「ビデオの長さの価格設定」と「プレミアムサブスクリプション」機能',
    bulk_feature2: 'さらに数分、3.3% オフで購入',
    premium_feature1_bulk: '毎月プレゼント<span style="color: #00d1b2;">1000分</span> <br><span style="color:#797676;font-size:14px;font-weight: 100;">(月をまたいで累積可能、サブスクリプションがキャンセルされるまで有効)</span>',
    bulk_feature1: '社名・背番号入り請求書ダウンロード',
    bulk_feature3: 'ユーザーに参加して議事録を共有し',
    bulk_feature4: '参加ユーザーに6種類の権限管理を提供',
    go_to_price: 'もっと理解する',
    choose_plan: 'プランを選択',
    annually: '年払いシステム',
    monthly: '月払いシステム',
    annually_save: '年会費の {0}% を節約',
    annually_deduct: '年間 {0} ドル請求',
    translation_memory: '翻訳メモリ',
    translation_memory_tooltip: '類似の文を迅速に比較し、翻訳の精度と一貫性を向上させます。',
}