module.exports = { 
    home: 'Inicio',
    audiotovideolang: 'Convertir audio a vídeo',
    speakerlang: 'Etiquetado de oradores para vídeos',
    translatelang: 'Traducir vídeo',
    aianalysislang: 'Herramienta de análisis con IA',
    addsubtitleslang: 'Añadir subtítulos a vídeo',
    converttextlang: 'Convertir vídeo a texto',
    collaboratelang: 'Colaborar con equipos',
    transcriptstospeakerslang: 'Añadir etiquetado de oradores a transcripciones existentes',
    transcriptstosubtitleslang: 'Importar archivo TXT o SRT',
}