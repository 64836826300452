module.exports = {
    convert_to_text_title_meta:"Online AI Video Summarizer: Add Topics, Summarize Meetings, Create YouTube Chapters with One Click",
    convert_to_text_desc_meta:"Our AI analysis tool allows you to efficiently summarize content, pinpoint key speakers, craft narration scripts, and integrate YouTube chapters and timestamps. Explore our range of templates, including video summarizers, YouTube timestamp generators, and video topic timestamp generators. Start with a free trial today!",
    convert_to_text_title:"AI Analysis",
    convert_to_text_desc:"Video summarizer, Youtube Chapters, Meeting Overview",
    start_your_trial:"Start your free trial!",
    hero_img:"./11_English.webp",

    three_step_title:"轉文字三步驟",
    step_one:"Ask a question or give a command",
    step_one_img:"./aianalysis_step1_en_US.webp",
    step_one_desc:"You can choose whether you want the AI video tool to summarize your content, identify key speakers, suggest narration scripts, and add YouTube Chapters and timestamps.",
    
    step_two:"Choose from predefined commands",
    step_two_img:"./aianalysis_step2_en_US.webp",
    step_two_desc:"AI Analysis offers predefined templates like a video summarizer generator, YouTube timestamp generator, and video timestamp topic generator.",

    step_three:"Summarize in your preferred language",
    step_three_img:"./aianalysis_step3_en_US.webp",
    step_three_desc:"Regardless of the video's original language, you can summarize or give commands in your preferred language.",

    feature_tutorial_poster_url:'./aianalysis_poster_en_US.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/PWOWwbHQCUM?autoplay=1',

    feature_why_image:'./ai_template_demo_en.webp',

    feature_extra_desc: "<ul><li><h2>AI Analysis: Unlock Video Insights Fast and Easy</h2><br>Need to get the gist of a video without watching every second? Our AI Analysis tool has you covered! Built just for you, this handy feature dives into your uploaded videos, giving you quick summaries and tons of ways to use them. Whether you’re making YouTube videos, writing blogs, or sorting out meeting notes, it does the heavy lifting with one click—no complicated steps required.<br><br></li><li><h2>Video Summaries and Catchy Titles</h2><br>The AI pulls out the big ideas from your video and suggests five awesome titles, for example:<br><br><strong>Blog Title</strong>: Grab readers with something fun and clickable<br><strong>News Article Title</strong>: Sharp and professional, ready for the headlines<br><strong>YouTube Video Title</strong>: Packed with keywords to get more views<br><strong>Website Title</strong>: Short, sweet, and great for search engines<br><strong>Meeting Title</strong>: Neat and tidy for sharing or notes<br><br></li><li><h2>YouTube Timelines and Chapters Made Simple</h2><br>Want viewers to find the good stuff fast? The AI creates a timeline for you, for example:<br><br>00:00 Quick Hello<br>00:15 Pandemic Updates and Thoughts<br>01:22 Breaking Down Findings and Risks<br>03:47 Keeping People Calm and Informed<br>05:00 Food Safety and Hot Topics<br><br>It makes your videos feel pro and keeps people watching.<br><br></li><li><h2>Fix Typos in a Snap</h2><br>Got text from your video? The AI spots any slip-ups and lets you fix them with one click. Perfect for bloggers or anyone who wants clean, polished content.<br><br></li><li><h2>Ask Anything, Anytime</h2><br>It’s like having a friend who knows your video inside out. Ask stuff like, “What’s the fix they mentioned?” or “What did the city say?” and get clear answers fast.<br><br></li></ul>",    
    feature_what_title: "Frequently Asked Questions",   
    feature_what_desc: "<ul><li><h2>How do I figure out a video’s main points fast with AI Analysis?</h2><br>Our AI Analysis tool grabs the key stuff from your video and sums it up for you. Whether it’s for YouTube, a blog, or meeting notes, it saves you time and makes your work flow better—great for marketing or creating content.<br><br></li><li><h2>Can it whip up titles that help with SEO?</h2><br>You bet! It gives you five title options—blog, news, YouTube, website, and meeting styles—all tweaks to boost your search rankings and get more clicks.<br><br></li><li><h2>How does it make YouTube timelines and chapters?</h2><br>The tool maps out your video with a timeline and chapters, so viewers can skip to what they want. It’s a simple way to keep them hooked and fits right in with today’s marketing vibes.<br><br></li><li><h2>Will it catch typos or proofread subtitles?</h2><br>Yep! It finds mistakes in your video text and fixes them with one click. It’s a lifesaver for making your content look sharp and helping with SEO too.<br><br></li><li><h2>Can I ask questions about the video and get answers right away?</h2><br>For sure! With the Q&A mode, you can ask anything about your video, and the AI responds fast—like your own personal video guru.<br><br></li></ul>"
}