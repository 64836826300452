module.exports = {
    convert_to_text_title_meta: "在线AI视频分析：自动视频摘要、会议重点、YouTube时间轴",
    convert_to_text_desc_meta: "AI技术提供视频摘要、自动YouTube章节划分及一键下标题功能。专为会议总结和YouTube视频分析而设计，提高内容的可视性和易用性。免费体验，立即注册开始使用！",
    convert_to_text_title:"AI分析",
    convert_to_text_desc:"AI影片摘要、自动Youtube章节、一键下标题",
    start_your_trial:"马上试！",
    hero_img:"./11_Chinese.webp",
   
    three_step_title:"转文字三步骤",
    step_one:"影片摘要及Youtube时间轴",
    step_one_img:"./aianalysis_step1_zh.webp",
    step_one_desc:"从预设范本分析或下指令让AI分析为影片下标题、做总结、甚至提供旁白脚本建议等内容。",
   
    step_two:"快速掌握不同语言的影片",
    step_two_img:"./aianalysis_step2_zh.webp",
    step_two_desc:"按下开始分析后能让一部30分钟的影片透过「AI分析」直接变成短短几百字的并以你熟悉的语言做总结。学习范围瞬间扩及全球！ ",
   
    step_three:"更快速创作影片",
    step_three_img:"./aianalysis_step3_zh.webp",
    step_three_desc:"使用「AI分析」能够迅速提供不同场景适合的标题、总结影片内容并抓出精华、提供Youtube时间轴章节，快速完成影片后制流程。 ",
   
    //feature_tutorial_title:'如何使用AI分析功能？ ',
    //feature_tutorial_desc:'「AI分析」功能是一个专门设计来深度分析影片内容的强大工具。透过这项功能，使用者可以对上传的影片进行全面的概要总结，或从提供的范本例如：影片总结、AI下标题、Youtube时间轴、Youtube章节等一键分析省去下指令的烦恼。 从而大大提升对影片主旨的理解和互动效率。 ',
    feature_tutorial_poster_url:'./aianalysis_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/Z3hioBKvl0M?autoplay=1',
   
    feature_why_image:'./ai_template_demo_zh.webp',
   // feature_extra_title:'AI分析功能：深度解析影片内容，提升你的理解与效率',
    feature_extra_desc:"<ul><li><h2>AI分析功能：深度解析影片内容，提升你的理解与效率</h2><br>想要快速掌握影片重点，又不想花时间逐字逐句分析？我们的「AI分析」功能是你最佳的帮手！这款专为台湾用户打造的强大工具，能帮你深度剖析上传的影片内容，提供全面的总结与多样化的应用，让你轻松提升效率。不管是制作YouTube内容、撰写部落格，还是整理会议记录，AI分析都能一键搞定，省去繁琐指令的麻烦。 <br><br></li><li><h2>影片总结与标题建议</h2><br>AI会自动帮你整理影片主旨，并提供五种实用标题建议，涵盖：<br><br><strong>部落格标题</strong>：吸引读者点击的吸睛标题<br><strong>新闻文章标题</strong>：专业又精准的报导风格<br><strong>YouTube影片标题</strong>：最佳化关键字，增加曝光量<br><strong>网站标题</strong>：简洁有力，提升SEO排名<br><strong>会议标题</strong>：条理清晰，方便记录与分享<br><br></li><li><h2>YouTube时间轴与章节分段</h2><br>想让观众快速找到重点？ AI会自动生成时间轴，例如：<br><br>00:00 开场介绍<br>00:15 疫情最新反应与探讨<br>01:22 解剖结果与毒性分析<br>03:47 如何安抚民众与解惑<br>05:00 食安问题与台资光争议<br><br>轻松提升影片的互动性和专业感，让你的YouTube频道更具吸引力。 <br><br></li><li><h2>自动错字校正</h2><br>输入影片文字后，AI会自动揪出潜在错字，并提供一键修正功能，让内容更干净、专业，适合部落客或内容创作者使用。 <br><br></li><li><h2>专属问答模式</h2><br>像ChatGPT一样，你可以随时针对影片内容提问，例如「这段提到什么解决方案？」或「台北市政府怎么回应？」AI会即时解答，让你秒懂影片精髓。 <br><br></li></ul>",

    feature_what_title:"常见问题与解答",
    feature_what_desc:"<ul><li><h2>如何利用AI影片分析快速掌握影片重点？ </h2><br>使用我们的AI影片分析功能，能够自动萃取影片中最关键的资讯并生成影片摘要。无论是YouTube影片、部落格内容或会议记录，均可迅速掌握重点，大幅提升工作效率，成为您行销与内容创作的得力助手。 <br><br></li><li><h2>AI影片分析能否自动生成多种标题以优化SEO？ </h2><br>AI影片分析功能会根据影片内容自动产出五种不同风格的标题，包括部落格、新闻、YouTube、网站和会议标题。这些标题均经过精心设计，帮助提升搜寻引擎排名与点击率，增加曝光量。 <br><br></li><li><h2>如何利用AI自动生成YouTube时间轴与章节？ </h2><br>我们的AI分析工具能根据影片内容自动产生清晰的YouTube时间轴与章节，让观众能迅速跳转至感兴趣的部分。不仅改善观看体验，也让影片互动性更高，符合现代影音行销趋势。 <br><br></li><li><h2>AI影片分析是否支援自动改错与字幕校正？ </h2><br>完全支援！ AI影片分析功能能自动侦测影片文字中的潜在错字，并提供一键改正功能，确保内容无误。这不仅提升影片专业度，也有助于内容创作及SEO优化。 <br><br></li><li><h2>使用AI影片分析能否进行影片内容提问并获得即时回应？ </h2><br>内建的问答模式让您可以随时针对影片内容发问，AI将提供即时且精准的解答，协助您深入了解每个细节，就像拥有一位专属的ChatGPT影片专家。 <br><br></li></ul>"
}