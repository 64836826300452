module.exports = {
    convert_to_text_title_meta:"線上AI語音轉文字：自動將影片、錄音、Youtube轉為逐字稿",
    convert_to_text_desc_meta:"線上自動將影片、錄音語音、Youtube轉文字，支援40多種語言並提供高準確率。功能包括逐字稿輸出、會議記錄人物分段，以及便於上字幕的分段處理。免費體驗，立即註冊開始使用！",
    convert_to_text_title:"語音轉文字",
    convert_to_text_desc:"自動將MP4影片，MP3錄音轉為嵌入時間碼的逐字稿，支援超過40種語言，自動分段，高準確率。將影片或錄音自動轉為文字的最佳線上工具！",
    start_your_trial:"馬上試！",
    hero_img:"./01_Chinese.webp",

    three_step_title:"語音轉文字三步驟",
    step_one:"選擇上傳影音檔",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"從電腦、Youtube、Google Drive、Zoom或影音檔下載連結選擇影片或錄音後上傳",
    
    step_two:"選擇轉成文字方式",
    step_two_img:"./select_split_zh.webp",
    step_two_desc:"從清單選取: 1) AI自動生成 2) 匯入純文字檔 3)手動輸入。分段方式選擇：以「逐字稿」形式分段。",

    step_three:"導出",
    step_three_img:"./select_export_zh.webp",
    step_three_desc:"進入編輯平台進行校正。完後即可從右上角以各種形式導出(srt,txt,pdf,mp4)",

    feature_tutorial_title:'如何將語音轉文字？',
    feature_tutorial_desc:'無論您需要將MP4或MP3文件進行語音轉文字，我們的編輯平台都可以協助您進行逐字稿的調整、段落編排和時間對齊，最終可以導出調整後的文件。<br>Taption提供多種語音轉文字方式包括：<span class="bullet-point">•</span>AI自動上字幕 <span class="bullet-point">•</span>導入已有的SRT檔 <span class="bullet-point">•</span>匯入TXT純文字檔 <span class="bullet-point">•</span>手動輸入。<br>我們的強大編輯平台將大幅縮短校對時間，同時Taption支持超過50種語言的雙語字幕製作。歡迎觀看我們的教學影片，了解如何有效使用語音轉文字功能！點開看我們的教學影片：',
    // feature_tutorial_poster_url:'./convert-to-text-tutorial-poster-zh.webp',
    // feature_tutorial_youtube:'https://www.youtube.com/embed/6HYYEdiifbM?autoplay=1',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/6HYYEdiifbM?autoplay=1',

    // feature_extra_title:"語音轉文字的用途",
    // feature_extra_desc:"語音轉文字不僅用於上字幕，還適用於多種場合：<ul><li>方便企劃或剪輯師迅速定位特定內容或字詞在哪部影片的哪一段落出現，語音轉文字功能便於引用之前的影片內容，實現主題的呼應。</li><li>修改和審閱影片內容時，語音轉文字方便像是撰寫影片的文案，對即興創作者在事後優化內容特別有幫助。</li><li>將影片翻譯成文字稿和擷取重點翻譯變得特別方便，語音轉文字提升多語種內容的創作效率。</li><li>將影片內容轉成逐字稿後，進行修改和潤飾成文章發布，語音轉文字有助於提高SEO，特別適合同時經營Vlog和Blog的創作者。</li><li>語音轉文字功能使聽力障礙者也能享受影片帶來的信息和價值。</li></ul><br>通過語音轉文字功能，我們不僅提高了內容的可及性，也增強了影片的多用途性和實用價值。",
    feature_extra_title:"常見問題與解答",
    feature_extra_desc:"<ul><li><h2>Taption 支援哪些語言進行語音轉文字與自動上字幕？</h2><br>Taption 目前支援超過 50 種語言與方言，包括繁體中文、英文、日文、韓文等。只要在上傳影音檔案時選擇目標語言，系統就會自動辨識並生成對應字幕。若您需要為影片提供雙語字幕或其他語言翻譯，也可在同一平台中使用 AI 翻譯功能，一次完成多語種製作。<br><br></li><li><h2>如何將 MP4 或 MP3 檔案自動轉成逐字稿？</h2><br>在 Taption 平台登入後，點擊「上傳」並選擇 MP4 或 MP3 檔案，系統即透過語音轉文字功能，自動產生含時間碼的逐字稿。轉錄完成後，您可進行細節校對，並導出 SRT、TXT、PDF 等格式，應用於字幕製作或文字紀錄。<br><br></li><li><h2>可否同時使用 AI 自動上字幕和雙語字幕翻譯？</h2><br>可以的！Taption 提供 AI 自動上字幕功能，並支援多語種翻譯。您可先生成繁體中文字幕，再利用 AI 翻譯工具快速產生另一種語言的字幕。此功能能有效節省多語言影片製作時間，並擴展影片的國際受眾群。<br><br></li><li><h2>我想要區分不同的發言人，Taption 能夠自動標記人物嗎？</h2><br>Taption 的語音轉文字功能支援多講者區隔，可嘗試自動偵測並標記不同的發言人角色。若辨識不夠精準，您亦可在編輯平台中進行手動調整與命名，讓逐字稿更完整並提升檔案閱讀的可讀性與可管理性。<br><br></li><li><h2>Taption 需要付費嗎？有免費試用方案嗎？</h2><br>我們提供免費試用方案，讓您體驗語音轉文字、AI 自動上字幕等核心功能。若您需更高轉錄時數或進階功能（如多用戶協作、批次匯出），可選擇付費方案。詳情請參考<a href=\"https://www.taption.com/pricing/zh\">價格</a>頁面或聯繫客服，以取得最符合您需求的方案建議。<br><br></li></ul>",
    
    feature_why_image: "./translate_step1_zh.webp",
    feature_what_title:'語音轉成文字5步驟',
    feature_what_desc: "<ul>\n  <li>註冊新帳號或使用 Gmail 登入：前往 <a href=\"https://www.taption.com/login/zh\">Taption 登入頁面</a>。</li>\n  <li>點擊「上傳」按鈕，選擇影音來源，如從電腦上傳、YouTube、Google Drive、Zoom 或直接貼上影音檔連結。</li>\n  <li>選擇轉寫所需的「語言」及「分段方式」。</li>\n  <li>完成上傳後，檔案會自動轉成文字並可進入編輯平台進行編輯。</li>\n  <li>在編輯平台，您可以進行<a href=\"https://www.taption.com/translate/zh\">翻譯</a>、<a href=\"https://www.taption.com/ai-analysis/zh\">AI分析</a>，最後將文件或字幕導出並嵌入至影片檔案中。</li>\n</ul>"

   // feature_what_desc:'自動語音轉文字（ASR，Automatic Speech Recognition）是一種技術，能夠讓電腦或其他裝置通過聲學和語言模型識別和處理人的語音。簡而言之，ASR 能夠讓機器聽懂我們的話並將錄音或影片轉換成文字。<br><br>ASR 的運作基於幾個核心步驟：首先是聲學模型識別語音中的聲音單元；然後語言模型用於理解這些聲音單元組成的詞和語句的語義和結構；最後，系統將這些信息組合起來，輸出一段文字，即逐字稿。<br><br>ASR 的應用非常廣泛，包括語音轉文字、AI助手（如Siri、Google Assistant）、語音導航系統、客服AI等。隨著技術的不斷進步，ASR 的準確性和效能正在不斷提高，使其在我們的日常生活中扮演著越來越重要的角色。'
}