module.exports = { 
    home: 'ホーム',
    audiotovideolang: '音声を動画に変換',
    speakerlang: '動画の話し手ラベル付け',
    translatelang: '動画の翻訳',
    aianalysislang: 'AI 分析ツール',
    addsubtitleslang: '動画に字幕を挿入',
    converttextlang: '動画をテキストに変換',
    collaboratelang: 'チームと共有・コラボ',
    transcriptstospeakerslang: 'スピーカーへのトランスクリプト',
    transcriptstosubtitleslang: 'TXTまたはSRTファイルをインポート',
}