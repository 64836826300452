module.exports = {
    convert_to_text_title:"影音人物标记",
    convert_to_text_desc:"自动为影音标记人物",
    start_your_trial:"马上试！",
    hero_img:"./04_Chinese.webp",

    step_one:"上传影片或录音档",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"从电脑，Youtube, Google Drive或Zoom选取要标记人物的影音档案。",
    
    step_two:"选择「发声者」来分段",
    step_two_img:"./speaker_step2_zh.webp",
    step_two_desc:"上传后分段方式选择「发声者」后文字会自动根据说话人物分段并标记。 ",

    step_three:"完成",
    step_three_img:"./speaker_step3_zh.webp",
    step_three_desc:"转成文字后后进入编辑平台进行调整后即可导出！",

    feature_tutorial_title:'自动人物标记教学',
    feature_tutorial_desc:"<ul><li><strong>自动人物标记</strong>：透过转换影片为文字并自动侦测、区分不同讲话者的对话，让内容结构更井然有序</li><li><strong>自动识别讲话者</strong>：无需人工逐段辨认与分割，Taption 会根据声音特征自动分段，节省大量时间与人力</li><li><strong>自订命名与编辑</strong>：可将系统预设的匿名标签改成主持人、来宾、工程师等具名标签，更贴近实际情境</li><li><strong>多种字幕格式汇出</strong>：完成标记后，支援 SRT、VTT 等常见字幕格式以及自订格式，便于不同平台与工作流程的整合</li><li><strong>轻松建立影片大纲</strong>：每位讲者的重点发言都能清楚分段，方便后续编辑或整理要点</li><li>Taption 帮你完成大部分繁复的标记工作，让你能更专注于内容优化与发布，同时在第一时间协助观众掌握发言重点</li></ul>",
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/tr8qrM8mnNA?autoplay=1',
    //https://youtu.be/tr8qrM8mnNA

    feature_extra_title:"常见问题与解答",
    feature_extra_desc:"<ul><li><h2>如何替一段录音或影片快速生成逐字稿并区分不同讲者？</h2><br>现今有不少线上工具可将影片或音档转成文字，但 Taption 除了提供一般的「一键转录」外，还能自动为不同的声音分段，形成条理分明的对话记录。这对于想要即时掌握会议内容、课程精华或访谈纪录的用户相当方便。 <br><br></li><li><h2>在制作线上课程字幕时，如何标注讲师与助教的对话？ </h2><br>很多线上课程都有助教或来宾参与，Taption 的「自动人物标记」功能能将不同身分的发言分开记录，并让你自行更改标签，如「讲师」、「助教」，或依照各人名设定。对于后制剪辑或学生复习都更清晰直观。 <br><br></li><li><h2>我需要汇出字幕给不同平台，哪个工具能提供多种字幕格式并且保留讲话者标签？ </h2><br>Taption 不只支援 SRT 和 VTT，还能以自订格式汇出字幕档，保留每位讲者的标记与对话时序。对于同时需要在 YouTube、线上课程平台或自家网站发布影片的人而言，一次就能完成所有档案的准备。 <br><br></li><li><h2>如何在产品宣传影片中让观众知道哪位主管或专家正在发言？ </h2><br>宣传影片通常包含主管、产品经理或客户的回馈访谈。借助 Taption 的自动人物标记功能，你能在短时间内为影片加上精准的字幕标签，帮助观众快速对号入座，提升整体内容的可信度与专业度。 <br><br></li><li><h2>想替会议录音制作要点报告，除了逐字稿外，还需要清楚区分部门代表的发言，该如何处理？ </h2><br>采用 Taption 转录会议录音时，系统能根据声音特征自动分割不同部门代表，产生清楚的字幕或对话分段。你可在后续直接汇出文字档，将内容整理成要点报告，同时保留「自动人物标记」的完整纪录。 <br><br></li></ul>"
}