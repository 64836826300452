module.exports = { 
    monthly_free_minutes: 'Monthly free accumulated minutes (Valid only when subscribed)',
    monthly_free_minutes_tooltip: 'If the monthly minutes are not consumed, they will be carried over to the next month and will be valid until the subscription is canceled. <br>These monthly minutes are used before the additional minutes purchased separately',
    additional_seat: 'Additional seats',
    additional_seat_tooltip: 'Enable account sharing by inviting users and granting them access to minutes, videos, and features with customizable permissions.',
    monthly_free_ai_credits: 'AI credits <a href="https://www.taption.com/ai-analysis" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">learn more</a>',
    monthly_free_ai_credits_tooltip: 'AI credits refill up to {0} monthly for Premium Subscription.<br>AI credits refill up to {1} monthly for Bulk Subscription.<br>Additional AI credits can be purchased anytime.',
    monthly_free_ai_credits_desc: 'Up to {0} credits monthly',
    minutes_price: 'Price for addtional minutes',
    minutes_price_tooltip: 'The additional minutes purchased separately do not expire after the subscription is canceled.',
    max_file_length: 'Maximum video length',
    max_file_size: 'Maximum upload file size',
    max_label_number: 'Maximum number of labels',
    automated_transcription: 'Automated transcription',
    editing_platform_unlimited: 'No time limit on editing platform usage',
    language_supported: '40+ languages supported',
    highlight_text: 'Highlighted text editor',
    translate: 'Translate into 50+ languages',
    translate_basic_tooltip: 'Translation will deduct addition minutes',
    translate_premium_tooltip: 'Translation will deduct addition minutes',
    translate_corp_tooltip: 'Translation will deduct addition minutes',
    personal_dictionary: 'Personal AI Dictionary',
    allow_commercial: 'Allow for commercial use',
    mp4_multi_language_export_tooltip: 'Supports any 2 languages subtitles from the 50+ available translations. You can add more translations from the translate dropdown on the top right once you go into the transcript editing page.',
    mp4_multi_language_export: 'Supports burned-in multi-language subtitles',
    mp4_multi_language_export_basic_tooltip: 'Exporting video will deduct additional minutes for non-subscription users.',
    mp4_audio_export: 'Convert audio into video with subtitles and customized background',
    mp4_audio_export_tooltip: 'You can convert and export your audio file into a customized background video with burned-in subtitles.',
    mp4_audio_export_basic_tooltip: 'Standard plan users can only export video with a black color background.',
    api_integration: 'Custom API integration',
    api_integration_tooltip: 'Please contact us at help@taption.com for further information.',
    api_integration_basic_tooltip: 'Please contact us at help@taption.com for further information.',
    api_integration_premium_tooltip: 'Please contact us at help@taption.com for further information.',
    api_integration_corp_tooltip: 'Please contact us at help@taption.com for further information.',
    custom_payments: 'Custom invoicing options',
    custom_payments_tooltip: 'Flexible payment options including pay by wire/check. Please contact us at help@taption.com for further information.',
    advanced_training: 'Advanced training for users',
    advanced_training_tooltip: 'Need assist on getting everyone onboard? We can help with that.',
    high_definition: 'Original quality video',
    high_definition_tooltip: 'Edit and share your video in its original quality',
    mp4_export: 'Unlimited burned-in subtitles exports',
    mp4_export_basic_tooltip: 'Exporting video will deduct additional minutes for non-subscription users.',
    editable_transcript_sharing: 'Editable transcript sharing',
    dark_mode: 'Dark Mode',
    video_editing: 'Edit video by editing text',
    video_cutting: 'Video cutting',
    multi_upload: 'Transcribe multiple files at once',
    invoice_company: 'Invoice with customized title',
    invoice_taxid: 'Invoice with tax ID number',
    share_minutes: 'Multi-user access',
    share_minutes_tooltip: 'Add users to account to share minutes and transcripts.',
    permission_users: 'User permissions management <a href="https://www.taption.com/collaborate" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">learn more</a>',
    permission_users_tooltip: 'Restrict added accounts to translate, upload, delete, export and edit transcripts.',
    max_char: 'Preset maximum character for captions',
    max_char_tooltip: 'The maximum number of characters for the captions to be shown at a time.<br> The transcript will split into sections according to this threshold.',
    direct_link: 'Accept links as sources when transcribing',
    direct_link_tooltip: 'Supports the following:<br>• Facebook video link<br>• Instagram video link<br>• X (formerly Twitter) video link<br>• TikTok video link<br>• Media file URL link<br>• Dropbox sharing link<br>• One Drive sharing link<br>• Google Drive sharing link<br><br>',
    auto_extent_subtitle: 'Auto extend duration for each subtitle',
    auto_extent_subtitle_tooltip: 'Extend all subtitles\' duration by a certain time length. <br/>Doing so can avoid "blinking" subtitles between short pauses which smoothens the transition.',
    clipped_video: 'Transcribe within time range',
    clipped_video_tooltip: 'Extract and transcribe within the selected time range of the media file.<br>Supports Youtube, mp3 and mp4 type media.',
    custom_format: 'Customized download format',
    custom_format_tooltip: 'When exporting your transcript as txt or pdf, our flexible template builder will allow you to download in your desired format.',
    editing_font_size: 'Customize the font size in editing platform',
    low_cost: 'Affordable pricing.',
    title_basic: 'Pay as you go',
    basic_cost_usd: '$8',
    basic_cost_ntd: '(240 NTD)',
    hour: 'hour',
    basic_feature5: 'Credit <span style="color: #00d1b2">60 minutes</span>',
    basic_feature1: 'Automated transcription',
    basic_feature2: '40+ languages supported',
    basic_feature3: 'Highlighted text editor',
    basic_feature4: 'Translate into 50+ languages',
    basic_feature7: 'Personal AI Dictionary',
    basic_feature6: 'Allow for commercial use',
    basic_feature8: 'Supports burned-in multi-language subtitles',
    title_premium: 'Premium Subscription',
    premium_cost_usd: '$12',
    premium_cost_ntd: '(360 NTD)',
    premium_basic_cost_usd: '$6',
    month: 'month',
    premium_before: '<span class="landing-arrow-left">&#10229;</span>All features in「Pay as you go」',
    premium_feature1: '<span style="color: #00d1b2">120 minutes</span> credit to your account every month.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Carryover minutes, valid until subscription canceled)</span>',
    premium_feature1_tooltip: 'If the monthly minutes are not consumed, they will be carried over to the next month and will be valid until the subscription is canceled. <br>These monthly minutes are used before the additional minutes purchased separately. <br>The additional minutes purchased separately do not expire after the subscription is canceled.',
    premium_feature2: '25% discount on hourly purchases',
    premium_feature3: 'Unlimited burned-in subtitles exports for free',
    premium_feature4: 'Editable transcript sharing',
    premium_feature5: 'Export your audio recordings to videos with subtitles',
    premium_feature6: 'Video cutting (Exported transcript/subtitles will auto adjust)',
    title_corp: 'Bulk Subscription',
    bulk_cost_usd: '$69',
    bulk_cost_ntd: '(2070 NTD)',
    bulk_basic_cost_usd: '$4',
    bulk_before: '<span class="landing-arrow-left">&#10229;</span>All features in「Pay as you go」and「Premium Subscription」',
    bulk_feature2: '63% discount on hourly purchases',
    premium_feature1_bulk: '<span style="color: #00d1b2">1000 minutes</span> credit to your account every month.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Carryover minutes, valid until subscription canceled)</span>',
    bulk_feature1: 'Provide receipts download with your institution name and tax id',
    bulk_feature3: 'Share minutes with users',
    bulk_feature4: '6 different configurable permissions',
    go_to_price: 'More details',
    choose_plan: 'Choose a plan',
    annually: 'Annual',
    monthly: 'Monthly',
    annually_save: 'Save {0}% with annual billing',
    annually_deduct: '${0} billed annually',
    unit: 'USD',
    translation_memory:'Translation Memory',
    translation_memory_tooltip: 'Quickly compare similar sentences previously translated to improve translation accuracy and consistency.'
}