module.exports = { 
    home: '홈 화면으로',
    audiotovideolang: '음성을 동영상으로 변환',
    speakerlang: '동영상내 발화자 라벨 붙이기',
    translatelang: '동영상 번역하기',
    aianalysislang: 'AI 분석 도구',
    addsubtitleslang: '동영상에 자막 추가',
    converttextlang: '동영상을 텍스트로 변환',
    collaboratelang: '팀원들과 협업하기',
    transcriptstospeakerslang: '발표자 트랜스크립트',
    transcriptstosubtitleslang: 'TXT 또는 SRT 파일 가져오기',
}