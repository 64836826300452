module.exports = { 
    monthly_free_minutes: 'Minutes gratuites cumulées mensuelles (Valables uniquement en cas d’abonnement)',
    monthly_free_minutes_tooltip: 'Si les minutes mensuelles ne sont pas consommées, elles seront reportées au mois suivant et seront valides jusqu’à l’annulation de l’abonnement. <br>Ces minutes mensuelles sont utilisées avant les minutes supplémentaires achetées séparément.',
    additional_seat: 'Sièges supplémentaires',
    additional_seat_tooltip: 'Permet le partage de compte en invitant des utilisateurs et en leur accordant un accès aux minutes, vidéos et fonctionnalités avec des autorisations personnalisables.',
    monthly_free_ai_credits: 'Crédits IA <a href="https://www.taption.com/ai-analysis" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">en savoir plus</a>',
    monthly_free_ai_credits_tooltip: 'Les crédits IA se rechargent jusqu’à {0} mensuels pour l’abonnement Premium.<br>Les crédits IA se rechargent jusqu’à {1} mensuels pour l’abonnement Bulk.<br>Des crédits IA supplémentaires peuvent être achetés à tout moment.',
    monthly_free_ai_credits_desc: 'Jusqu’à {0} crédits mensuels',
    minutes_price: 'Prix des minutes supplémentaires',
    minutes_price_tooltip: 'Les minutes supplémentaires achetées séparément n’expirent pas après l’annulation de l’abonnement.',
    max_file_length: 'Durée maximale de la vidéo',
    max_file_size: 'Taille maximale de fichier à télécharger',
    max_label_number: 'Nombre maximal d’étiquettes',
    automated_transcription: 'Transcription automatisée',
    editing_platform_unlimited: 'Pas de limite de temps pour l’utilisation de la plateforme d’édition',
    language_supported: 'Plus de 40 langues prises en charge',
    highlight_text: 'Éditeur de texte mis en évidence',
    translate: 'Traduire en plus de 50 langues',
    translate_basic_tooltip: 'La traduction déduira des minutes supplémentaires',
    translate_premium_tooltip: 'La traduction déduira des minutes supplémentaires',
    translate_corp_tooltip: 'La traduction déduira des minutes supplémentaires',
    personal_dictionary: 'Dictionnaire IA personnalisé',
    allow_commercial: 'Autorisé pour un usage commercial',
    mp4_multi_language_export_tooltip: 'Prend en charge les sous-titres dans 2 langues parmi les 50+ traductions disponibles. Vous pouvez ajouter plus de traductions à partir du menu déroulant de traduction en haut à droite une fois que vous accédez à la page d’édition de transcription.',
    mp4_multi_language_export: 'Prend en charge les sous-titres multilingues intégrés',
    mp4_multi_language_export_basic_tooltip: 'L’exportation de vidéo déduira des minutes supplémentaires pour les utilisateurs sans abonnement.',
    mp4_audio_export: 'Convertir un audio en vidéo avec sous-titres et arrière-plan personnalisé',
    mp4_audio_export_tooltip: 'Vous pouvez convertir et exporter votre fichier audio en une vidéo de fond personnalisée avec des sous-titres intégrés.',
    mp4_audio_export_basic_tooltip: 'Les utilisateurs du plan Standard peuvent uniquement exporter une vidéo avec un fond de couleur noire.',
    api_integration: 'Intégration d’API personnalisée',
    api_integration_tooltip: 'Veuillez nous contacter à help@taption.com pour plus d’informations.',
    api_integration_basic_tooltip: 'Veuillez nous contacter à help@taption.com pour plus d’informations.',
    api_integration_premium_tooltip: 'Veuillez nous contacter à help@taption.com pour plus d’informations.',
    api_integration_corp_tooltip: 'Veuillez nous contacter à help@taption.com pour plus d’informations.',
    custom_payments: 'Options de facturation personnalisées',
    custom_payments_tooltip: 'Options de paiement flexibles, y compris le virement bancaire/ chèque. Veuillez nous contacter à help@taption.com pour plus d’informations.',
    advanced_training: 'Formation avancée pour les utilisateurs',
    advanced_training_tooltip: 'Besoin d’aide pour que tout le monde soit opérationnel ? Nous pouvons vous aider.',
    high_definition: 'Vidéo de qualité d’origine',
    high_definition_tooltip: 'Éditez et partagez votre vidéo dans sa qualité d’origine',
    mp4_export: 'Exportations illimitées de sous-titres intégrés',
    mp4_export_basic_tooltip: 'L’exportation de vidéo déduira des minutes supplémentaires pour les utilisateurs sans abonnement.',
    editable_transcript_sharing: 'Partage de transcription éditable',
    dark_mode: 'Mode sombre',
    video_editing: 'Éditer la vidéo en éditant le texte',
    video_cutting: 'Découpage de vidéo',
    multi_upload: 'Transcrire plusieurs fichiers à la fois',
    invoice_company: 'Facture avec titre personnalisé',
    invoice_taxid: 'Facture avec numéro d’identification fiscale',
    share_minutes: 'Accès multi-utilisateurs',
    share_minutes_tooltip: 'Ajoutez des utilisateurs au compte pour partager des minutes et des transcriptions.',
    permission_users: 'Gestion des autorisations utilisateur <a href="https://www.taption.com/collaborate" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">en savoir plus</a>',
    permission_users_tooltip: 'Restreignez les comptes ajoutés pour traduire, télécharger, supprimer, exporter et éditer les transcriptions.',
    max_char: 'Nombre maximal de caractères pour les sous-titres',
    max_char_tooltip: 'Nombre maximal de caractères pour les sous-titres affichés à la fois.<br> La transcription sera divisée en sections en fonction de ce seuil.',
    direct_link: 'Accepter les liens comme sources lors de la transcription',
    direct_link_tooltip: 'Prend en charge les liens suivants:<br>• Lien vidéo Facebook<br>• Lien vidéo Instagram<br>• Lien vidéo X (anciennement Twitter)<br>• Lien vidéo TikTok<br>• Lien URL de fichier multimédia<br>• Lien de partage Dropbox<br>• Lien de partage One Drive<br>• Lien de partage Google Drive<br><br>',
    auto_extent_subtitle: 'Étendre automatiquement la durée de chaque sous-titre',
    auto_extent_subtitle_tooltip: 'Prolongez la durée de tous les sous-titres d’une certaine durée. <br/>Cela permet d’éviter les sous-titres « clignotants » entre les pauses courtes, ce qui rend la transition plus fluide.',
    clipped_video: 'Transcrire dans une plage de temps',
    clipped_video_tooltip: 'Extraire et transcrire dans la plage de temps sélectionnée du fichier multimédia.<br>Prend en charge les médias de type Youtube, mp3 et mp4.',
    custom_format: 'Format de téléchargement personnalisé',
    custom_format_tooltip: 'Lors de l’exportation de votre transcription au format txt ou pdf, notre générateur de modèle flexible vous permettra de télécharger dans le format souhaité.',
    editing_font_size: 'Personnaliser la taille de la police dans la plateforme d’édition',
    low_cost: 'Tarification abordable.',
    title_basic: 'À l’utilisation',
    basic_cost_usd: '8 $',
    basic_cost_ntd: '(240 NTD)',
    hour: 'heure',
    basic_feature5: 'Crédit <span style="color: #00d1b2">60 minutes</span>',
    basic_feature1: 'Transcription automatisée',
    basic_feature2: 'Plus de 40 langues prises en charge',
    basic_feature3: 'Éditeur de texte mis en évidence',
    basic_feature4: 'Traduire en plus de 50 langues',
    basic_feature7: 'Dictionnaire IA personnalisé',
    basic_feature6: 'Autorisé pour un usage commercial',
    basic_feature8: 'Prend en charge les sous-titres multilingues intégrés',
    title_premium: 'Abonnement Premium',
    premium_cost_usd: '12 $',
    premium_cost_ntd: '(360 NTD)',
    premium_basic_cost_usd: '6 $',
    month: 'mois',
    premium_before: '<span class="landing-arrow-left">&#10229;</span>Toutes les fonctionnalités de « À l’utilisation »',
    premium_feature1: '<span style="color: #00d1b2">120 minutes</span> créditées sur votre compte chaque mois.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Minutes cumulables, valides jusqu’à l’annulation de l’abonnement)</span>',
    premium_feature1_tooltip: 'Si les minutes mensuelles ne sont pas consommées, elles seront reportées au mois suivant et seront valides jusqu’à l’annulation de l’abonnement. <br>Ces minutes mensuelles sont utilisées avant les minutes supplémentaires achetées séparément. <br>Les minutes supplémentaires achetées séparément n’expirent pas après l’annulation de l’abonnement.',
    premium_feature2: '25% de réduction sur les achats à l’heure',
    premium_feature3: 'Exportations illimitées de sous-titres intégrés gratuites',
    premium_feature4: 'Partage de transcription éditable',
    premium_feature5: 'Exportez vos enregistrements audio en vidéos avec sous-titres',
    premium_feature6: 'Découpage vidéo (la transcription/les sous-titres exportés s’ajusteront automatiquement)',
    title_corp: 'Abonnement Bulk',
    bulk_cost_usd: '69 $',
    bulk_cost_ntd: '(2070 NTD)',
    bulk_basic_cost_usd: '4 $',
    bulk_before: '<span class="landing-arrow-left">&#10229;</span>Toutes les fonctionnalités de « À l’utilisation » et « Abonnement Premium »',
    bulk_feature2: '63% de réduction sur les achats à l’heure',
    premium_feature1_bulk: '<span style="color: #00d1b2">1000 minutes</span> créditées sur votre compte chaque mois.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Minutes cumulables, valides jusqu’à l’annulation de l’abonnement)</span>',
    bulk_feature1: 'Téléchargez les reçus avec le nom de votre institution et le numéro d’identification fiscale',
    bulk_feature3: 'Partagez des minutes avec des utilisateurs',
    bulk_feature4: '6 autorisations configurables différentes',
    go_to_price: 'Plus de détails',
    choose_plan: 'Choisir un plan',
    annually: 'Annuel',
    monthly: 'Mensuel',
    annually_save: 'Économisez {0}% avec la facturation annuelle',
    annually_deduct: '${0} facturés annuellement',
    unit: 'Dollar américain',
    translation_memory: 'Mémoire de traduction',
    translation_memory_tooltip: 'Comparez rapidement des phrases similaires précédemment traduites pour améliorer la précision et la cohérence de la traduction.',
}