module.exports = { 
    home: 'Startseite',
    audiotovideolang: 'Audio in Video konvertieren',
    speakerlang: 'Sprecherbeschriftung für Videos',
    translatelang: 'Video übersetzen',
    aianalysislang: 'KI-Analyse-Tool',
    addsubtitleslang: 'Untertitel zu Video hinzufügen',
    converttextlang: 'Video in Text konvertieren',
    collaboratelang: 'Mit Teams zusammenarbeiten',
    transcriptstospeakerslang: 'Sprecherbeschriftung zu vorhandenen Transkripten hinzufügen',
    transcriptstosubtitleslang: 'TXT- oder SRT-Datei importieren',
}