module.exports = {
    convert_to_text_title:"影音人物標記",
    convert_to_text_desc:"自動為影音標記人物",
    start_your_trial:"馬上試！",
    hero_img:"./04_Chinese.webp",

    step_one:"上傳影片或錄音檔",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"從電腦，Youtube, Google Drive或Zoom選取要標記人物的影音檔案。",
    
    step_two:"選擇「發聲者」來分段",
    step_two_img:"./speaker_step2_zh.webp",
    step_two_desc:"上傳後分段方式選擇「發聲者」後文字會自動根據說話人物分段並標記。",

    step_three:"完成",
    step_three_img:"./speaker_step3_zh.webp",
    step_three_desc:"轉成文字後後進入編輯平台進行調整後即可導出！",

    feature_tutorial_title:'自動人物標記教學',
    feature_tutorial_desc:"<ul><li><strong>自動人物標記</strong>：透過轉換影片為文字並自動偵測、區分不同講話者的對話，讓內容結構更井然有序</li><li><strong>自動識別講話者</strong>：無需人工逐段辨認與分割，Taption 會根據聲音特徵自動分段，節省大量時間與人力</li><li><strong>自訂命名與編輯</strong>：可將系統預設的匿名標籤改成主持人、來賓、工程師等具名標籤，更貼近實際情境</li><li><strong>多種字幕格式匯出</strong>：完成標記後，支援 SRT、VTT 等常見字幕格式以及自訂格式，便於不同平台與工作流程的整合</li><li><strong>輕鬆建立影片大綱</strong>：每位講者的重點發言都能清楚分段，方便後續編輯或整理要點</li><li>Taption 幫你完成大部分繁複的標記工作，讓你能更專注於內容優化與發佈，同時在第一時間協助觀眾掌握發言重點</li></ul>",
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/tr8qrM8mnNA?autoplay=1',
    //https://youtu.be/tr8qrM8mnNA

    feature_extra_title:"常見問題與解答",
    feature_extra_desc:"<ul><li><h2>如何替一段錄音或影片快速生成逐字稿並區分不同講者？</h2><br>現今有不少線上工具可將影片或音檔轉成文字，但 Taption 除了提供一般的「一鍵轉錄」外，還能自動為不同的聲音分段，形成條理分明的對話記錄。這對於想要即時掌握會議內容、課程精華或訪談紀錄的用戶相當方便。<br><br></li><li><h2>在製作線上課程字幕時，如何標註講師與助教的對話？</h2><br>很多線上課程都有助教或來賓參與，Taption 的「自動人物標記」功能能將不同身分的發言分開記錄，並讓你自行更改標籤，如「講師」、「助教」，或依照各人名設定。對於後製剪輯或學生複習都更清晰直觀。<br><br></li><li><h2>我需要匯出字幕給不同平台，哪個工具能提供多種字幕格式並且保留講話者標籤？</h2><br>Taption 不只支援 SRT 和 VTT，還能以自訂格式匯出字幕檔，保留每位講者的標記與對話時序。對於同時需要在 YouTube、線上課程平台或自家網站發布影片的人而言，一次就能完成所有檔案的準備。<br><br></li><li><h2>如何在產品宣傳影片中讓觀眾知道哪位主管或專家正在發言？</h2><br>宣傳影片通常包含主管、產品經理或客戶的回饋訪談。借助 Taption 的自動人物標記功能，你能在短時間內為影片加上精準的字幕標籤，幫助觀眾快速對號入座，提升整體內容的可信度與專業度。<br><br></li><li><h2>想替會議錄音製作要點報告，除了逐字稿外，還需要清楚區分部門代表的發言，該如何處理？</h2><br>採用 Taption 轉錄會議錄音時，系統能根據聲音特徵自動分割不同部門代表，產生清楚的字幕或對話分段。你可在後續直接匯出文字檔，將內容整理成要點報告，同時保留「自動人物標記」的完整紀錄。<br><br></li></ul>"
}