module.exports = {
    convert_to_text_title_meta:"線上AI影片分析：自動影片摘要、會議重點、Youtube時間軸",
    convert_to_text_desc_meta:"AI技術提供影片摘要、自動YouTube章節劃分及一鍵下標題功能。專為會議總結和YouTube影片分析而設計，提高內容的可視性和易用性。免費體驗，立即註冊開始使用！",
    convert_to_text_title:"AI分析",
    convert_to_text_desc:"AI影片摘要、自動Youtube章節、一鍵下標題",
    start_your_trial:"馬上試！",
    hero_img:"./11_Chinese.webp",

    three_step_title:"轉文字三步驟",
    step_one:"影片摘要及Youtube時間軸",
    step_one_img:"./aianalysis_step1_zh.webp",
    step_one_desc:"從預設範本分析或下指令讓AI分析為影片下標題、做總結、甚至提供旁白腳本建議等內容。",
    
    step_two:"快速掌握不同語言的影片",
    step_two_img:"./aianalysis_step2_zh.webp",
    step_two_desc:"按下開始分析後能讓一部30分鐘的影片透過「AI分析」直接變成短短幾百字的並以你熟悉的語言做總結。學習範圍瞬間擴及全球！",

    step_three:"更快速創作影片",
    step_three_img:"./aianalysis_step3_zh.webp",
    step_three_desc:"使用「AI分析」能夠迅速提供不同場景適合的標題、總結影片內容並抓出精華、提供Youtube時間軸章節，快速完成影片後製流程。",

    feature_tutorial_poster_url:'./aianalysis_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/Z3hioBKvl0M?autoplay=1',

    feature_why_image:'./ai_template_demo_zh.webp',
   // feature_extra_title:'AI分析功能：深度解析影片內容，提升你的理解與效率',
    feature_extra_desc:"<ul><li><h2>AI分析功能：深度解析影片內容，提升你的理解與效率</h2><br>想要快速掌握影片重點，又不想花時間逐字逐句分析？我們的「AI分析」功能是你最佳的幫手！這款專為台灣用戶打造的強大工具，能幫你深度剖析上傳的影片內容，提供全面的總結與多樣化的應用，讓你輕鬆提升效率。不管是製作YouTube內容、撰寫部落格，還是整理會議記錄，AI分析都能一鍵搞定，省去繁瑣指令的麻煩。<br><br></li><li><h2>影片總結與標題建議</h2><br>AI會自動幫你整理影片主旨，並提供五種實用標題建議，涵蓋：<br><br><strong>部落格標題</strong>：吸引讀者點擊的吸睛標題<br><strong>新聞文章標題</strong>：專業又精準的報導風格<br><strong>YouTube影片標題</strong>：最佳化關鍵字，增加曝光量<br><strong>網站標題</strong>：簡潔有力，提升SEO排名<br><strong>會議標題</strong>：條理清晰，方便記錄與分享<br><br></li><li><h2>YouTube時間軸與章節分段</h2><br>想讓觀眾快速找到重點？AI會自動生成時間軸，例如：<br><br>00:00 開場介紹<br>00:15 疫情最新反應與探討<br>01:22 解剖結果與毒性分析<br>03:47 如何安撫民眾與解惑<br>05:00 食安問題與台資光爭議<br><br>輕鬆提升影片的互動性和專業感，讓你的YouTube頻道更具吸引力。<br><br></li><li><h2>自動錯字校正</h2><br>輸入影片文字後，AI會自動揪出潛在錯字，並提供一鍵修正功能，讓內容更乾淨、專業，適合部落客或內容創作者使用。<br><br></li><li><h2>專屬問答模式</h2><br>像ChatGPT一樣，你可以隨時針對影片內容提問，例如「這段提到什麼解決方案？」或「台北市政府怎麼回應？」AI會即時解答，讓你秒懂影片精髓。<br><br></li></ul>",
   
    feature_what_title:"常見問題與解答",
    feature_what_desc:"<ul><li><h2>如何利用AI影片分析快速掌握影片重點？</h2><br>使用我們的AI影片分析功能，能夠自動萃取影片中最關鍵的資訊並生成影片摘要。無論是YouTube影片、部落格內容或會議記錄，均可迅速掌握重點，大幅提升工作效率，成為您行銷與內容創作的得力助手。<br><br></li><li><h2>AI影片分析能否自動生成多種標題以優化SEO？</h2><br>AI影片分析功能會根據影片內容自動產出五種不同風格的標題，包括部落格、新聞、YouTube、網站和會議標題。這些標題均經過精心設計，幫助提升搜尋引擎排名與點擊率，增加曝光量。<br><br></li><li><h2>如何利用AI自動生成YouTube時間軸與章節？</h2><br>我們的AI分析工具能根據影片內容自動產生清晰的YouTube時間軸與章節，讓觀眾能迅速跳轉至感興趣的部分。不僅改善觀看體驗，也讓影片互動性更高，符合現代影音行銷趨勢。<br><br></li><li><h2>AI影片分析是否支援自動改錯與字幕校正？</h2><br>完全支援！AI影片分析功能能自動偵測影片文字中的潛在錯字，並提供一鍵改正功能，確保內容無誤。這不僅提升影片專業度，也有助於內容創作及SEO優化。<br><br></li><li><h2>使用AI影片分析能否進行影片內容提問並獲得即時回應？</h2><br>內建的問答模式讓您可以隨時針對影片內容發問，AI將提供即時且精準的解答，協助您深入了解每個細節，就像擁有一位專屬的ChatGPT影片專家。<br><br></li></ul>"

}